import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/images/conclusion.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Conclusion and Affirmation</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Conclusion</h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="Notes on a board featuring brainstorming ideas" /></span>
                    <p>I discovered the MS in Informatics program at SJSU while searching online for graduate programs from established brick and mortar universities. I had worked professionally as a UI designer for many years and became interested in studying user experience design after working with many talented UX professionals. I wanted to find a graduate program that had a foundational emphasis on user experience with a technical focus on data science and information management. The Informatics program at SJSU fulfilled my requirements, and includes courses focusing on big data analysis, web application development, metadata and taxonomic systems creation, health informatics, human computer interaction, and cyber security. This diverse range of courses gave me the opportunity to explore creating usable solutions for end users through many different facets, and by the end of the program, I had a strong portfolio of projects demonstrating what I’ve learned.</p>

                    <p>Although I initially hoped to learn more about UX design during this program, I was unsure about the role data analysis and management played in creating usable solutions for end users. However, as I completed each course, I learned more about how informatics skills can be applied to many different fields, like healthcare and cybersecurity.  Furthermore, many of my classes emphasized the importance of data governance and protecting user privacy, which are both necessary in order to create secure data-driven applications. I also learned about the diverse role informaticists can play in a large variety of careers, including health informatics, data analysis, web development, and user experience design. Finally, this program has helped me become a more diverse designer, and I’ve gained skills in UX design, taxonomic and metadata systems creation, web development, and big data analysis. I look forward to applying the knowledge I’ve gained during my tenure in this program to my career and hope to create technological solutions that are both secure and usable in the future.</p>
                </div>
            </section>
            <section id="overview">
              <div className="inner">
                <h3 className="major">
                  Affirmation
                </h3>
                <blockquote>
                  <ol>
                    <li>All introductory, reflective, and evidentiary work submitted is mine alone (except where indicated as a group or team project), and has been prepared solely by me.</li>
                    <li>I am protecting the privacy of the contents of my e-Portfolio by password protecting it or by sharing the URL only with my e-Portfolio advisor.</li>
                    <li>Before making my e-Portfolio public I will respect the privacy of others by removing mention in this e-Portfolio of information that could lead to the identity of individuals (team members in group projects, internship supervisors, interviewees, etc.) and institutions. </li>
                    <li>Amanda Tjahjadi</li>
                  </ol>
                </blockquote>
              </div>
            </section>
        </div>

    </Layout>
)

export default Generic
